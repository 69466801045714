(function($) {
    cssLoaded(function(){
        $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    $.libInit(".part_ui_link, .part_ui_btn", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-lib-fancybox]", "lib_gallery", function(selector) {
        bodyLoaded(function(){
            $.importStyle(cdnjs.fancybox_css);
            $.importScript(cdnjs.fancybox, function(){
                $(selector).each(function(){
                    let id = $(this).data("lib-fancybox");
                    $(this).fancybox({
                        selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                        transitionEffect: "slide",
                        animationEffect: "zoom",
                        buttons: [
                            "close"
                        ]
                    });
                });
                $("[data-lib-fancybox-open]").on("click", function(){
                    let id = $(this).data("lib-fancybox-open");
                    $(`[data-lib-fancybox="${id}"] .col:first-of-type .lib--fancybox-item`).trigger("click")
                })
            });
        });
    });

    $.libInit("[data-lib-switch]", "lib_switch", function(selector) {
        $(selector).nl_lib_switch();
    });

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
})(jQuery);